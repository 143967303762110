<template>
  <v-main>
    <v-img
      :src="require(`@/assets/guardr2.png`)"
      dark
      gradient="to top, #00000080, #00000080"
      min-height="100vh"
      :height="$vuetify.breakpoint.mdAndUp ? '100vh' : undefined"
    >
      <div
        :class="[$vuetify.breakpoint.mdAndUp && 'fill-height']"
        class="d-block d-md-flex"
      >
        <page-app-bar />

        <router-view :key="$route.path" />

        <page-footer />
      </div>
    </v-img>
  </v-main>
</template>

<script>
export default {
  name: 'PageView',

  components: {
    PageAppBar: () => import('./AppBar'),
    PageFooter: () => import('./Footer'),
  },
};
</script>
